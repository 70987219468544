import React from "react";
import {
  Container,
  Segment,
  Header,
  Image,
  Button,
  Icon,
  List,
} from "semantic-ui-react";
import logo from "../images/logoFincrea.png";

const Landingpage = () => {
  return (
    <Container textAlign="center" style={{ padding: "2em 1em" }}>
      {/* Top Section: Company Logo and Contact Details */}
      <Segment basic>
        <Image centered src={logo} size="medium" />
        <Header as="h4" color="grey" style={{ marginTop: "1em" }}>
          Viale della Regione Siciliana
          <br />
          Nord Ovest, 3414 - Palermo
          <br />
          Tel: 091 6090106
          <br />
          Email: <a href="mailto:info@fincrea.it">info@fincrea.it</a>
        </Header>
      </Segment>

      {/* Legal and Registration Info */}
      <Segment basic>
        <Header as="h6">
          <small>
            Fincrea di Mangiaracina Nicolò, n° OAM-A12164, P.IVA 04975110828 CF:
            MNGNCL70P05G273D
            <br />
            In esclusiva in collaborazione con Dynamica Ratail S.p.A. (intern.
            iscr. all'Albo degli intermediari finanziari ex art. 106 TUB, n.
            181)
            <br />a cui il Consumatore può rivolgersi per attività di
            istruttoria del prestito, definizione dei rapporti contabili ed ogni
            altra attività afferente esclusivamente alla conclusione
            dell'affare.
          </small>
        </Header>
      </Segment>

      {/* Company Overview */}
      <Segment vertical>
        <Header as="h3" dividing>
          Chi Siamo e Cosa Facciamo
        </Header>
        <p style={{ fontSize: "1.1em", lineHeight: "1.6" }}>
          Fincrea si dedica a fornire un supporto specializzato ai consumatori
          nella gestione delle pratiche di prestito, semplificando il percorso
          per ottenere finanziamenti con trasparenza e professionalità. Grazie
          alla nostra esperienza e alla collaborazione con Dynamica Ratail
          S.p.A., offriamo consulenza personalizzata e assistenza completa
          durante tutte le fasi della trattativa finanziaria.
        </p>
      </Segment>

      {/* Services */}
      <Segment vertical>
        <Header as="h3" dividing>
          I Nostri Servizi
        </Header>
        <List
          bulleted
          size="large"
          style={{ textAlign: "left", display: "inline-block" }}
        >
          <List.Item>
            <strong>Istruttoria del Prestito:</strong> Supporto completo per
            l’analisi e la valutazione delle richieste.
          </List.Item>
          <List.Item>
            <strong>Gestione dei Rapporti Contabili:</strong> Assistenza nella
            definizione e nel controllo dei rapporti finanziari.
          </List.Item>
          <List.Item>
            <strong>Consulenza Finanziaria Personalizzata:</strong> Consulenza
            mirata per orientarti nella scelta della soluzione finanziaria più
            adatta.
          </List.Item>
          <List.Item>
            <strong>Servizio Clienti Dedicato:</strong> Supporto costante per
            rispondere a ogni tua esigenza.
          </List.Item>
        </List>
      </Segment>

      <Segment vertical>
        <Header as="h3" dividing>
          Trasparenza e Conformità
        </Header>
        <p style={{ fontSize: "1.1em", lineHeight: "1.6" }}>
          Ci impegniamo a operare in totale trasparenza. Sul nostro sito{" "}
          <a href="https://www.dynamicaretail.it">Dynamica Retail</a> troverai
          le informazioni per la privacy che regolano l’utilizzo dei nostri
          servizi e dimostrano il nostro impegno nel rispettare le normative
          vigenti, come il GDPR.
        </p>
      </Segment>

      {/* Call-to-Action Buttons */}
      <Segment vertical>
        <Button
          icon
          labelPosition="right"
          primary
          onClick={() => window.open("https://www.dynamicaretail.it", "_blank")}
          style={{ margin: "0.5em" }}
        >
          Informazioni <Icon name="info circle" />
        </Button>
        <Button
          icon
          labelPosition="right"
          primary
          onClick={() =>
            window.open("https://www.dynamicaretail.it/trasparenza/", "_blank")
          }
          style={{ margin: "0.5em" }}
        >
          Trasparenza <Icon name="magnify" />
        </Button>
      </Segment>

      {/* Quality Commitment */}
      <Segment vertical>
        <Header as="h4">Il Nostro Impegno per la Qualità</Header>
        <p style={{ fontSize: "1em", lineHeight: "1.6" }}>
          Il nostro sito è realizzato per offrire un'esperienza utente chiara e
          professionale, garantendo che tutte le informazioni necessarie siano
          facilmente accessibili. Con una comunicazione trasparente e
          dettagliata, dimostriamo la legittimità della nostra attività e il
          nostro impegno verso la sicurezza e la protezione dei dati.
        </p>
      </Segment>
    </Container>
  );
};

export default Landingpage;
